import React from 'react'

import Loader from './Loader'

import styles from './PageLoader.module.less'

const PageLoader = () => {
  return (
    <div className={styles.wrapper}>
      <Loader />
    </div>
  )
}

export default PageLoader

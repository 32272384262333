import reduceReducers from 'reduce-reducers'

import translations from './translations'

const initialState = {
  locale: 'en',
}

const reducer = reduceReducers(initialState, translations)

export default reducer

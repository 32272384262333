import React from 'react'

import { useSelector } from 'react-redux'

import { getLocale } from 'I18N'
import { IntlProvider } from 'react-intl'

import { Layout as AntDLayout } from 'antd'

import Header from '../Header'
import { Route } from 'Router'

import styles from './PageLayout.module.less'

const { Header: AntDHeader, Content: AntDContent } = AntDLayout

const PageLayout = ({ children }) => {
  const { locale } = useSelector((state) => state.i18n)

  const { key: localeKey, messages } = getLocale(locale)

  return (
    <IntlProvider locale={localeKey} messages={messages}>
      <AntDLayout>
        <div className={styles['page-wrapper']}>
          <AntDHeader className={styles['header']}>
            <Header />
          </AntDHeader>

          <div className={styles['content']}>
            <AntDContent>
              <Route />
            </AntDContent>
          </div>
        </div>
      </AntDLayout>
    </IntlProvider>
  )
}

export default PageLayout

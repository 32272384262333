import React, { useState } from 'react'

import { useIntl } from 'react-intl'
import { useChangeLocale } from 'I18N/hooks'

import { isMobile } from 'react-device-detect'

import { Link, useLocation } from 'react-router-dom'

import { Menu, Typography } from 'antd'

import classnames from 'classnames'
import styles from './Header.module.less'
import {
  HomeOutlined,
  ContactsOutlined,
  VideoCameraAddOutlined,
  GlobalOutlined,
  TagOutlined,
} from '@ant-design/icons'
import { ReactComponent as EnglishFlag } from 'Assets/Flags/en_flag.svg'
import { ReactComponent as RomanianFlag } from 'Assets/Flags/ro_flag.svg'

const Header = () => {
  const location = useLocation()

  const [selected, setSelected] = useState(location.pathname)

  const handleClick = (e) => {
    if (!e.key.includes('language')) {
      setSelected(e.key)
    }
  }

  const { formatMessage } = useIntl()
  const navLeftItems = [
    {
      id: 'home',
      path: '/home',
      label: formatMessage({ id: 'header.menu-item.home' }),
      Icon: HomeOutlined,
    },
    {
      id: 'contact',
      path: '/contact',
      label: formatMessage({ id: 'header.menu-item.contact' }),
      Icon: ContactsOutlined,
    },
    {
      id: 'feed',
      path: '/feed',
      label: formatMessage({ id: 'header.menu-item.feed' }),
      Icon: VideoCameraAddOutlined,
    },
  ]

  const navRightItems = [
    {
      id: 'notice',
      path: '/notice',
      label: formatMessage({ id: 'header.menu-item.notice' }),
      Icon: TagOutlined,
    },
  ]

  const [changeLocale, locale] = useChangeLocale()
  const handleLanguageChange = (locale) => {
    changeLocale(locale)
  }

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[selected, `language:${locale}`]}
      mode="horizontal"
      className={classnames(styles['menu'], {
        [styles['menu-mobile']]: isMobile,
      })}
    >
      {navLeftItems.map(({ path, label, Icon }) => (
        <Menu.Item key={path}>
          <Link to={path}>
            <Typography.Title level={4}>
              <Icon
                style={{
                  fontSize: isMobile ? 24 : 16,
                  marginRight: isMobile ? 0 : 12,
                }}
              />
              {!isMobile && label}
            </Typography.Title>
          </Link>
        </Menu.Item>
      ))}

      <Menu.SubMenu
        key="languages"
        className={styles['language-button']}
        title={
          <Typography.Title level={4}>
            <GlobalOutlined
              style={{
                fontSize: isMobile ? 24 : 16,
                marginRight: isMobile ? 0 : 12,
              }}
            />
            {!isMobile && formatMessage({ id: 'header.menu-item.language' })}
          </Typography.Title>
        }
      >
        <Menu.Item
          key="language:en"
          onClick={() => handleLanguageChange('en')}
          className={styles['language-item']}
        >
          <EnglishFlag />
          {formatMessage({ id: 'header.menu-item.language-en' })}
        </Menu.Item>
        <Menu.Item
          key="language:ro"
          onClick={() => handleLanguageChange('ro')}
          className={styles['language-item']}
        >
          <RomanianFlag />
          {formatMessage({ id: 'header.menu-item.language-ro' })}
        </Menu.Item>
      </Menu.SubMenu>

      {navRightItems.map(({ path, label, Icon }) => (
        <Menu.Item
          key={path}
          className={classnames({
            [styles['right-button']]: !isMobile,
          })}
        >
          <Link to={path}>
            <Typography.Title level={4}>
              <Icon
                style={{
                  fontSize: isMobile ? 24 : 16,
                  marginRight: isMobile ? 0 : 12,
                }}
              />
              {!isMobile && label}
            </Typography.Title>
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default Header

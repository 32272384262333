import messagesEn from './Translations/en.json'
import messagesRo from './Translations/ro.json'

const localeData = [
  {
    key: 'en',
    messages: messagesEn,
  },
  {
    key: 'ro',
    messages: messagesRo,
  },
]

export const availableLocales = localeData.map((l) => l.key)

export const getLocale = (localeKey) => {
  return localeData.find((l) => l.key === localeKey)
}

import React from 'react'

import { Spin } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'
import styles from './Loader.module.less'

const antIcon = <LoadingOutlined className={styles.loader} spin />

const Loader = () => {
  return <Spin indicator={antIcon} size="large" />
}

export default Loader

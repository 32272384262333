import { SET_LOCALE } from '../constants'

const translations = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: payload,
      }

    default:
      return state
  }
}

export default translations

import { useSelector, useDispatch } from 'react-redux'

import { setLocale } from '../redux/actions'

const useChangeLocale = () => {
  const dispatch = useDispatch()

  const forceSetLocale = (locale) => {
    dispatch(setLocale(locale))
  }

  const { locale } = useSelector((state) => state.i18n)

  return [forceSetLocale, locale]
}

export default useChangeLocale

import React from 'react'

const PageNotFound = React.lazy(() => import('Screens/PageNotFound'))

const Home = React.lazy(() => import('Screens/Home'))
const Contact = React.lazy(() => import('Screens/Contact'))
const Feed = React.lazy(() => import('Screens/Feed'))
const Notice = React.lazy(() => import('Screens/Notice'))

const routes = [
  {
    id: 'home',
    name: 'Home',
    Component: Home,
    path: ['/home'],
  },
  {
    id: 'contact',
    name: 'Contact',
    Component: Contact,
    path: ['/contact'],
  },
  {
    id: 'feed',
    name: 'Feed',
    Component: Feed,
    path: ['/feed'],
  },
  {
    id: 'notice',
    name: 'Notice',
    Component: Notice,
    path: ['/notice'],
  },
]

export { PageNotFound }
export { Home as DefaultRoute }
export const [defaultRoute] = routes

export default routes
